.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Css for APP */
/* Chrome, Safari, Edge, Opera */

.input-field::-webkit-outer-spin-button,
.input-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

.input-field[type="number"] {
    -moz-appearance: textfield;
}

/*startquiz 1*/

.s-startquiz {
    width: 100%;
    height: 100vh !important;
    display: flex;
    font-family: "Poppins", sans-serif;
    flex-direction: row;
    background-color: #f5f1e8;
    min-height: 100%;
}

.startquiz-step1{
 width: 100%;
 display: flex;
 font-family: "Poppins", sans-serif;
 flex-direction: row;
 background-color: #f5f1e8;
 min-height: 100%;
}

@media(max-width: 600px){
  /*  .s-startquiz{
         height: 100% !important;
         }*/
         /* .s-startquiz-right{
            padding: 40px 0;
        } */
    }

    .popup,
    .s-native-plant,
    .w-newsletter {
        font-family: "Poppins", sans-serif;
    }

    .s-startquiz-left img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .s-startquiz-left,
    .s-startquiz-right {
        flex: 0 0 50%;
    }

    .startquiz-step1 .s-startquiz-right-content{
        padding: 0 70px;
    }

    .s-startquiz-right-content {
        padding: 0 65px;
        /* background-image: url("./../public/img/gfw-quiz-bg.jpg"); */
        padding-bottom: 22px;
        background-size: 100%;
        background-position: center;
    }

    .sun-bg {
        background-image: url("./../public/img/sun-quiz-bg.jpg");
    }

    .plant-native-bg {
        background-image: url("./../public/img/plant-native-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .space-bg {
        background-image: url("./../public/img/space-bg.jpg");
    }

    .s-startquiz-right {
        text-align: center;
        /* height: 100vh; */
        width: 100%;
        /* overflow: hidden;*/
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    /*startquiz 0*/

    /******* widgets ***********/

    /*prabox 1*/

    .w-prabox p {
        margin-top: 6px;
        font-size: 12px;
        color: #3e3835;
        margin-bottom: 0;
        padding-bottom: 15px 0 !important;
        font-weight: 500;
    }

    .pointer {
        cursor: pointer;
    }

    /*prabox 0*/

    /*heading 1*/

    .w-heading {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0;
        color: #3E3835 !important;
    }

    @media(max-width: 375px){
        .w-heading {
            line-height: 1.2 !important;
            font-size: 22px;
            padding: 0 10px;
        }
        .startquiz-step4 .w-heading{
          padding: 0 !important;
      }
  }

  @media only screen and (max-width: 420px) and (min-width: 375px){
    .w-heading{
        font-size: 22px !important;
    }
    .startquiz-step3 .w-heading{
        padding: 0 20px !important
    }
}

@media only screen and (max-width: 375px) and (min-width: 320px){
    .startquiz-step1 .w-prabox p{
        font-size: 10px;
    }
}

@media(max-width: 547px){
    .w-star-icon-txt p{
        font-size: 14px !important
    }

    .w-plant-option-box img{
        width: 60px !important
    }
}

/*heading 0*/

/*button 1*/

.quiz-btn {
    height: 48px;
    width: 210px;
    border-radius: 35px;
    background: #F5F1E8;
    display: inline-block;
    color: #CDC2AA;
    line-height: 3;
    text-decoration: none;
    border: 1px solid #CDC2AA;
    transition: 0.3s ease-in;
    text-align: center;
    cursor: pointer;
}

/*    .quiz-btn:hover {
        background-color: transparent;
        color: #51a6b5;
        }*/

        /*button 0*/

        /*back button 1*/

        .w-back-btn-box a {
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.05em;
            text-align: left;
            color: #3e3835;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
        }

        /*back button 0*/

        /*progress bar 1*/

/*    .w-progress-br ul::after {
        content: "";
        background-color: #cdc2aa;
        height: 3px;
        width: 98%;
        position: absolute;
        bottom: 6px;
        right: 0;
        z-index: -1;
        }*/

        .w-progress-br ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 150px;
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 1;
        }

        .w-progress-br ul li {
            display: inline-block;
        }

/*    .w-progress-br li span {
        content: url("./../public/img/dot.svg");
        vertical-align: middle;
        border-radius: 0px;
        }*/

        .w-progress-br .active span {
            content: url("./../public/img/flower.svg");
            position: relative;
            bottom: 8px;
            right: 5px;
            width: 17px;
        }

        /*progress bar 0*/

        .step-4-bg{
            background-image: url("./../public/img/quiz-bg-desktop-4.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .step-5-bg{
          background-image: url("./../public/img/gfw-quiz-bg-desktop-4-4.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }

      .leaf-bg {
        background-image: url("./../public/img/gfw-quiz-bg.jpg");
        /* background-size: 100%; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .direction-column {
        flex-direction: column;
    }

    .innr-nav {
        display: flex;
        justify-content: space-between;
        padding: 17px 25px;
    }

    .align-center {
        text-align: center;
        height: 100%;
        align-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .w-star-icon-txt p {
        text-transform: uppercase;
        color: #3e3835;
        font-weight: 500;
        /*  margin-top: 0;*/
    }

    .w-star-icon-txt p span {
        text-decoration: underline;
        cursor: pointer;
    }

    .style-white {
        background-color: #fff;
        color: #cdc2aa;
        border-color: #fff;
    }

    .style-disable {
        background-color: #f5f1e8;
        border-color: #cdc2aa;
        color: #cdc2aa;
    }

   .App input.input-field {
        height: 48px;
        width: 188px;
        border-radius: 25px;
        background: #fff;
        display: inline-block;
        color: #000;
        line-height: 3;
        text-decoration: none;
        border: 1px solid #eae4d6;
        transition: 0.3s ease-in;
        text-align: center;
        font-weight: 500;
    }

    .input-field::focus-visible {
        border-color: #fff !important;
        outline-color: #fff !important;
        box-shadow: none;
    }

    /* plant-native*/

    .w-plant-option-box {
        height: 190px;
        background: #ffffff;
        border-radius: 25px;
        border: 1px solid #eae4d6;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        margin: 5px;
    /* flex-grow: 1;
    flex-basis: 26%; */
    padding: 21px;
    box-sizing: border-box;
    width: 200px;
}

.plant-option {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 672px;
    justify-content: center;
}

img.hover-svg {
    display: none;
}

.w-plant-option-box:hover .wht-svg {
    display: none;
}

.w-plant-option-box:hover .hover-svg {
    display: block;
}

/*.w-progress-br ul li.completed:after {
    content: "";
    background-color: #12A5B5;
    height: 3px;
    width: 31%;
    position: absolute;
    bottom: 6px;
    left: 0;
    z-index: 1;
    }*/

    .no-bg-img {
        background-image: none !important;
    }

    /* plant-native*/

    .hide {
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
        -moz-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
        -o-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
    }

    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background: #fffdf9;
        -moz-border-radius: 5px;
        -o-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        width: 450px;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09), 0px 11px 12px 7px rgba(0, 0, 0, 0.02);
        -moz-transition: all 120ms;
        -webkit-transition: all 120ms;
        -o-transition: all 120ms;
        transition: all 120ms;
        z-index: 99;
        color: #3e3835;
        text-align: center;
        box-sizing: border-box;
    }

    .full-width-field {
        width: 100%;
    }

    .small-font p {
        font-size: 12px;
    }

    .overlay {
        position: fixed;
        /* Sit on top of the page content */
        width: 100%;
        /* Full width (cover the whole page) */
        height: 100%;
        /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        /* Black background with opacity */
        z-index: 2;
        /* Specify a stack order in case you're using a different order for other elements */
        cursor: pointer;
        /* Add a pointer on hover */
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 51px;
        height: 51px;
        font-size: 0;
        cursor: pointer;
    }

    .close:before,
    .close:after {
        content: "";
        position: absolute;
        background: #767676;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        -o-border-radius: 1px;
        border-radius: 1px;
        left: 14px;
        right: 14px;
        top: 30px;
        height: 3px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: all 100ms ease-in;
        -moz-transition: all 100ms ease-in;
        -webkit-transition: all 100ms ease-in;
        -o-transition: all 100ms ease-in;
    }

    .close:after {
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .w-prabox h4 {
        margin: 0;
        text-transform: uppercase;
        color: #3e3835;
        font-size: 18px;
        font-weight: 700;
    }

    .frm-small {
        width: 310px;
        margin: 0 auto;
    }

    .s-native-plant {
        padding: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .s-native-plant-left {
        width: 69%;
        display: inline-block;
    }

    .s-native-plant-right {
        width: 30%;
        display: inline-block;
    }

    .mb30 {
        margin-bottom: 30px !important;
    }

    .mb15 {
        margin-bottom: 15px;
    }

    .w-newsletter {
        display: flex;
        align-items: center;
        background-color: #e8f0c7;
        padding: 21px;
    }

    .w-newsletter-left {
        width: 49%;
        display: inline-block;
        padding-left: 36px;
    }

    .w-newsletter-right {
        width: 50%;
        display: inline-block;
    }

    .vertical-frm {
        justify-content: space-between;
    }

    .vertical-frm input {
        margin: 0 4px;
        display: inline-block;
    }

    .vertical-frm .email-field {
        width: 247px;
    }

    .vertical-frm .zip-field {
        width: 181px;
    }

    .vertical-frm .quiz-btn {
        width: 73px;
    }

    .w-newsletter-left h3 {
        font-weight: 400;
    }

    /*responsive css start*/

/*@media only screen and (max-width: 768px) and (min-width: 547px){
    .planting {
        height: 100vh;
        min-height: 100%;
    }
}

@media (max-width: 547px){
    .planting {
        height: 90vh;
        min-height: 100%;
    }
    }*/

    @media only screen and (max-width: 768px) and (min-width: 280px) {
        .s-startquiz {
            flex-direction: column;
        }
        .startquiz-step1{
          flex-direction: column;
      }
      .leaf-bg.s-startquiz .s-startquiz-right-content {
        padding:15% 2%!important;
        justify-content: flex-start;
        /* padding-bottom: 38px !important; */
    }

    .leaf-bg.s-startquiz.startquiz-step2 {
        height: 60vh !important;
        background-size: contain;
    }


    .s-startquiz-right-content .input-field[type=number] {
        width: 100% !important;
        margin-bottom: 0;
    }

    .s-startquiz-right-content .quiz-btn {
        width: 100%;
    }

    .s-startquiz-right {
      /*  padding-bottom: 57px;*/
      background-color: #f5f1e8;
  }

/*  .s-startquiz-right-content {
    background-image: url("./../public/img/gfw-quiz-bg-mble.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    }*/

    .leaf-bg {
        background-size: cover;
        background-position: bottom;
        height: 100%;
    }

    .w-plant-option-box {
        height: 95px;
        /* justify-content: space-between;*/
        flex-direction: row;
        padding: 21px 12px;
        box-sizing: border-box;
        width: 100%;
        justify-content: flex-start;
    }
    .w-plant-option-box p{
        text-align: left !important;
        padding-left: 15px;
        /* font-weight: 500;*/
        font-size: 14px;
    }
    .plant-option {
        width: 100%;
    }
    .popup {
        width: 92%;
    }
    .leaf-bg {
        background-image: url("./../public/img/gfw-quiz-bg-mble.jpg");
        background-size: cover;
        background-position: inherit;
    }
    .plant-native-bg {
        background-image: url("./../public/img/plant-native-bg-mble.jpg");
        background-size: cover;
        background-position: inherit;
    }
    .step-5-bg{
      background-image: url("./../public/img/gfw-quiz-bg-mobile-5.png");
      background-size: cover;
      background-position: inherit;
  }
  .step-4-bg{
      background-image: url("./../public/img/quiz-bg-mobile-4.png");
      background-size: cover;
      background-position: inherit;
  }
}


@media only screen and (max-width: 768px) and (min-width: 386px) {
   .startquiz-step2 .s-startquiz-right-content{
       padding: 0 6% !important;
   }
}

/*@media only screen and (max-width: 403px) and (min-width: 386px) {
   .startquiz-step2 .s-startquiz-right-content{
       padding: 0 6% !important;
   }
}
*/
@media only screen and (max-width: 386px) and (min-width: 370px) {
  .startquiz-step2 .s-startquiz-right-content{
   padding: 0 2% !important;
}
}

@media only screen and (max-width: 371px) and (min-width: 180px) {
  .startquiz-step2 .s-startquiz-right-content{
   padding: 0 10% !important;
}
}



/*responsive css end*/

/* Global Classes */
.error {
    color: red;
}

/*.option-selected{
    color: #000;
}
*/

.s-startquiz-right-content input:focus-visible,
.popup-body .input-field:focus-visible{
    outline: none !important;
}

/* .s-startquiz-right-content input:focus ~ a.quiz-btn {
    background: #12A5B5 !important;
    color: #fff;
    border-color: #51a6b5;
    } */

/* .popup-body input:focus ~ a.quiz-btn{
    background: #12a5b5 !important;
    color: #fff;
    border-color: #12a5b5;
    } */

    .skip p{
        font-size: 16px;
        text-transform: capitalize;
        text-decoration: underline;
        font-weight: 500;
    }

    .theme-btn{ 
        background-color: #51a6b5;
        color: #fff;
        border-color: #51a6b5;
    }

    .h-100vh{
        height: 100vh !important
    }

    #myPopup input{
        font-size: 15px !important
    }

/*    .s-startquiz,
    .startquiz-step1{
     height: 100% !important
     }*/

     @media(min-width: 600px){
        .startquiz-step1{
         height: 80vh !important;
     }
 }
 @media(max-width: 600px){
    /* .s-startquiz,
    .startquiz-step1{
       display: block !important;
   } */
   .startquiz-step1{
    height: 80vh !important;
}
   .s-startquiz-right,
   .startquiz-step1-right{
     height: 100% !important
 }

 .startquiz-step1 .s-startquiz-right-content{
    padding: 0 20px;
 }
 .s-startquiz-right .w-heading{
    font-size:22px!important;
 }
}

@media only screen and (max-width: 768px) and (min-width: 280px){
    .s-startquiz,
    .startquiz-step1 {
        flex-direction: column !important;
    }

    .startquiz-step1 .s-startquiz-left {
        max-height: 40vh;
    }

    .startquiz-step1 .s-startquiz-left img {
        max-height: 60vh;
    }

    .leaf-bg.s-startquiz.startquiz-step2 .w-prabox p {
        padding-bottom: 15px;
    }

}

@media( max-width: 768px){
    .h-100-percent-tab{
        height: 100% !important
    }
    /* .slider-wrapper {
            transform: translate(0%, 90%) !important;
            }*/
        }

        .step {
            width: 5px;
            height: 5px;
            background: #cdc2aa;
            border: 2px solid #cdc2aa;
            border-radius: 50%;
            transition: background 1s;
        }

        .step.completed {
            border: 2px solid #12A5B5;
            background: #12A5B5;
        }

        .slider-wrapper{
          position: absolute;
          right: 30px;
          transform: translate(0%, 90%);
      }



      .steps {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 200px;
      }

      .progress {
          position: absolute;
          width: 100%;
          height: 45%;
          border-bottom: 2px solid #cdc2aa;
          z-index: -1;
      }
      .percent {
          position: absolute;
          width: 0;
          height: 100%;
          border-bottom: 2px solid #12A5B5;
          z-index: 1;
          transition: width 1s;
      }


      /* Paste this css to your style sheet file or under head tag */
/* This only works with JavaScript, 
if it's not present, don't show loader */
.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(http://smallenvelop.com/demo/simple-pre-loader/images/loader-64x/Preloader_2.gif) center no-repeat #fff;
}

.behind-the-science{
    text-align: left;
}

.behind-the-science .w-prabox p{
    font-size: 15px !important
}

.bts-bg-image{
    background-image: url(https://cdn.shopify.com/s/files/1/0614/1126/2696/t/19/assets/bts-image.png);
    height: 150px;
}
.behind-the-science .padding{
    padding-top: 20px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.behind-the-science .close:before, 
.behind-the-science .close:after{
    background-color: #fff !important
}

/*.behind-the-science < .popup { 
    padding: 0 !important
}*/

.behind-the-science .w-star-icon-txt p span{
    text-decoration: none !important;
}

.behind-the-science .w-star-icon-txt p{
    margin-bottom: 10px !important
}

.email-popup{
            padding: 80px 25px;
}