.no-js #loader {
    display: none;
}
.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}
.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(http://smallenvelop.com/demo/simple-pre-loader/images/loader-64x/Preloader_2.gif) center no-repeat
        #fff;
}

.loader-container{
    
}
.spn-loader {
    width: 1.6em;
    height: 1.6em;
    margin: 0 auto;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    border: 3px solid #ffffff;
    border-top-color: #12A5B5;
}

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
